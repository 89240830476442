import { graphql, Script } from "gatsby"
import React, { useState } from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/hire-pages-compoents/Banner"
import Statics from "../components/hire-typescript/Statics"
import Empower from "../components/hire-pages-compoents/WhyShould"
import WhyHireAngular from "../components/hire-pages-compoents/WhyHireAngular"
import Maximize from "../components/hire-pages-compoents/Maximize"
import HowHireAngular from "../components/hire-saas-developer/EasySteps"
import HireDevelopers from "../components/hire-pages-compoents/HireDevelopers"
import WhyShould from "../components/hire-pages-compoents/WhyShould"
import AngularTechStacks from "../components/hire-vue-js-developers/TechnologiesUse"
import AngularIndustries from "../components/hire-pages-compoents/AngularIndustries"
import QuickReads from "../components/hire-laravel-developers/QuickReads"
import Solutions from "../components/hire-pages-compoents/Solutions"
import SolutionsMobile from "../components/hire-pages-compoents/SolutionsMobile"
import Success from "../components/hire-pages-compoents/Success"
import Remote from "../components//hire-pages-compoents/Remote"
import Combination from "../components/hire-pages-compoents/FullStackCombination"
import Finest from "../components/web-application/Opportunity"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <>
       <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const VueJs = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const heroSection = data?.strapiPage?.sections[0]
  const whyhire = data?.strapiPage?.sections[1]
  const whyShould = data?.strapiPage?.sections[2]
  const maximizeAngular = data?.strapiPage?.sections[3]
  const developers = data?.strapiPage.sections[4]
  const how = data?.strapiPage?.sections[5]
  const rubust = data?.strapiPage?.sections[6]
  const remote = data?.strapiPage?.sections[7]
  const best = data?.strapiPage?.sections[8]
  const TechStacksAngular = data?.strapiPage?.sections[9]
  const cta = data?.strapiPage?.sections[10]
  const industriesAngular = data?.strapiPage?.sections[11]
  const solutions = data?.strapiPage?.sections[12]
  const awesome = data?.strapiPage?.sections[13]
  const quick = data?.strapiPage?.sections[14]
  const faqs = data?.strapiPage?.sections[15]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  const devSDetails = [
    {
      name: "Zohaib Javed",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/zohaib_dev_f5e92a312e.png",
      experience: "6+ years of experience",
      desc: "Zohaib Javed is an experienced software engineer specializing in Vue.js development. He excels in creating dynamic and user-friendly web applications.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_8_3cbdc850f0.svg",
      reviews: "<b>4.8</b> Reviews",
      skills: [
        "HTML5",
        "REST APIs",
        "Node.js",
        "React.js",
        "D3.js",
        "Full Stack Developer",
        "JavaScript",
      ],
    },
    {
      name: "Lars Andersen",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/lars_dev_9f68794809.png",
      experience: "3+ years of experience",
      desc: "Lars excels in fast-paced, collaborative environments, crafting elegant solutions for high-quality software with designers and backend developers.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_6_2457829227.svg",
      reviews: "<b>4.8</b> Stars Reviews",
      skills: [
        "VueJS",
        " FrontEnd",
        "JavaScript",
        "Vue Router",
        "UI framework",
        "Vue Components",
        "Single Page Application",
        "State Management",
        "Web development",
        "Responsive design",
      ],
    },
    {
      name: "Ahmed Hassan",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/ahmed_dev_c3cdbcf626.png",
      experience: "3+ Years Of Experience",
      desc: "Ahmed, a Vue.js expert with four years of front-end programming experience, excels in crafting innovative, interactive user experiences.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_5_c3048ffdfe.svg",
      reviews: "<b>4.6</b> Stars Reviews",
      skills: [
        "VueJS",
        "FrontEnd Dev",
        "JavaScript",
        "Web Dev",
        "Vue Router",
        "Vue Components",
        "SPA (Single Page Application)",
        "UI/UX Design",
        "Agile development",
      ],
    },
  ]

  return (
    <MainLayout schemas={bodySchema}>
      <Banner
        strapiData={heroSection}
        breadCrumb={data?.strapiPage?.buttons}
        pageName="Hire Angular Developers"
        vueJsCard={true}
        vueJs={true}
      />
      <Statics />
      <WhyHireAngular strapiData={whyhire} whyHire={true} />
      <WhyShould strapiData={whyShould} />
      <Maximize strapiData={maximizeAngular} hireVueJs={true} />
      <HireDevelopers strapiData={developers} devSDetails={devSDetails} />
      <HowHireAngular strapiData={how} drupalSteps={true} />
      <Empower strapiData={rubust} Vueidentify={true} />
      <Remote strapiData={remote} vueJsID={true} developVue={true} />
      <Combination strapiData={best} />
      <AngularTechStacks strapiData={TechStacksAngular} />
      <Finest strapiData={cta} vueFuture={true} vsJsBanner />
      <AngularIndustries strapiData={industriesAngular} VueIndsDec={true} />
      {!isMobile && !isTablet ? (
        <Solutions strapiData={solutions} />
      ) : (
        <SolutionsMobile strapiData={solutions} />
      )}
      <Success strapiData={awesome} />
      <QuickReads strapiData={quick} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query VueJs {
    strapiPage(slug: { eq: "hire-vue-js-developers" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default VueJs
