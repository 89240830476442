// extracted by mini-css-extract-plugin
export var NextGen = "EasySteps-module--NextGen--61d2b";
export var bannerBtn = "EasySteps-module--bannerBtn--5ca5e";
export var cards = "EasySteps-module--cards--03129";
export var cvButtn = "EasySteps-module--cvButtn--765aa";
export var description = "EasySteps-module--description--174a8";
export var drupalStepsBg = "EasySteps-module--drupalStepsBg--10006";
export var drupalStepsDec = "EasySteps-module--drupalStepsDec--6a596";
export var heading = "EasySteps-module--heading--69a7f";
export var phoenixStepsDec = "EasySteps-module--phoenixStepsDec--d78e2";
export var productEasySteps = "EasySteps-module--productEasySteps--60914";